import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_qjtpnj } from './api/temp/searchcriteria';
import type { Methods as Methods_zjar3e } from './api/v1/abtests/_projectId@string';
import type { Methods as Methods_4k2f96 } from './api/v1/abtests/authenticated/_projectId@string';
import type { Methods as Methods_zx81u } from './api/v1/area';
import type { Methods as Methods_76f895 } from './api/v1/careerAdvisor/corporateClients/_corporateClientId@string';
import type { Methods as Methods_1bte5gt } from './api/v1/careerAdvisor/corporateClients/_corporateClientId@string/pageLink';
import type { Methods as Methods_1a4o39s } from './api/v1/careerAdvisor/jobs';
import type { Methods as Methods_znkox } from './api/v1/careerAdvisor/jobs/_arcsOrderId@number';
import type { Methods as Methods_1ijkuxd } from './api/v1/careerAdvisor/jobs/bookmark';
import type { Methods as Methods_1ctwem4 } from './api/v1/careerAdvisor/jobs/markAsRead';
import type { Methods as Methods_ou7n4l } from './api/v1/careerAdvisor/jobs/related/otherJobs';
import type { Methods as Methods_v7kqnq } from './api/v1/corporateClients/_corporateClientId@number/publicationPhotos';
import type { Methods as Methods_13lk0ru } from './api/v1/front/job/search/canonical';
import type { Methods as Methods_8ls0yn } from './api/v1/front/job/search/footerLinkPattern';
import type { Methods as Methods_1nl9x3n } from './api/v1/front/job/search/titlemeta';
import type { Methods as Methods_6lsh3h } from './api/v1/front/jobs/search/label';
import type { Methods as Methods_h9ruy5 } from './api/v1/industry';
import type { Methods as Methods_1if69eo } from './api/v1/job/_jid@string';
import type { Methods as Methods_1cgl7dt } from './api/v1/job/_jid@string/bookmark';
import type { Methods as Methods_tx49zq } from './api/v1/job/_jid@string/clicksCountUp';
import type { Methods as Methods_dxiw0c } from './api/v1/job/_jid@string/markAsRead';
import type { Methods as Methods_1ggb4dr } from './api/v1/job/_jid@string/pvCountUp';
import type { Methods as Methods_1njf7b1 } from './api/v1/job/_jid@string/relationInfoList/_corporateClientId@string';
import type { Methods as Methods_1kgnldy } from './api/v1/jobType';
import type { Methods as Methods_108luin } from './api/v1/jobs';
import type { Methods as Methods_3quz6n } from './api/v1/jobs/count';
import type { Methods as Methods_1hk2ini } from './api/v1/jobs/featured';
import type { Methods as Methods_ipxlww } from './api/v1/jobs/keywords';
import type { Methods as Methods_1nt8tj } from './api/v1/jobs/neararea';
import type { Methods as Methods_o81vz4 } from './api/v1/jobs/recommendations/interests';
import type { Methods as Methods_16zdarc } from './api/v1/jobs/topics/_id@string';
import type { Methods as Methods_13ju89s } from './api/v1/jwttest';
import type { Methods as Methods_1aqc38a } from './api/v1/prePublishedScout/jobs/_prePublishedScoutId@number';
import type { Methods as Methods_9h9im2 } from './api/v1/prePublishedScout/jobs/bookmark';
import type { Methods as Methods_c74r9t } from './api/v1/qualifications';
import type { Methods as Methods_zee37p } from './api/v1/railwayCompany';
import type { Methods as Methods_1arbdhu } from './api/v1/savedSearches';
import type { Methods as Methods_itimew } from './api/v1/savedSearches/_no@string';
import type { Methods as Methods_jr3h7h } from './api/v1/scout/job/_jid@string';
import type { Methods as Methods_1rp3slj } from './api/v1/scout/job/_jid@string/markAsRead';
import type { Methods as Methods_1y73qny } from './api/v1/selectJobs/_selectJobCode@string';
import type { Methods as Methods_1en3614 } from './api/v1/selectJobs/_selectJobCode@string/reference';
import type { Methods as Methods_1lob0jk } from './api/v1/selectJobs/jobs';
import type { Methods as Methods_xnzz7g } from './api/v1/selectJobs/jobs/count';
import type { Methods as Methods_kzqanu } from './api/v1/user/service/usageStatus';
import type { Methods as Methods_1w5ssoh } from './api/v1/users/integration/dodax';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://internal-hydrogen-alb-stg-2046329920.ap-northeast-1.elb.amazonaws.com' : baseURL).replace(/\/$/, '');
  const PATH0 = '/api/temp/searchcriteria';
  const PATH1 = '/api/v1/abtests';
  const PATH2 = '/api/v1/abtests/authenticated';
  const PATH3 = '/api/v1/area';
  const PATH4 = '/api/v1/careerAdvisor/corporateClients';
  const PATH5 = '/pageLink';
  const PATH6 = '/api/v1/careerAdvisor/jobs';
  const PATH7 = '/api/v1/careerAdvisor/jobs/bookmark';
  const PATH8 = '/api/v1/careerAdvisor/jobs/markAsRead';
  const PATH9 = '/api/v1/careerAdvisor/jobs/related/otherJobs';
  const PATH10 = '/api/v1/corporateClients';
  const PATH11 = '/publicationPhotos';
  const PATH12 = '/api/v1/front/job/search/canonical';
  const PATH13 = '/api/v1/front/job/search/footerLinkPattern';
  const PATH14 = '/api/v1/front/job/search/titlemeta';
  const PATH15 = '/api/v1/front/jobs/search/label';
  const PATH16 = '/api/v1/industry';
  const PATH17 = '/api/v1/job';
  const PATH18 = '/bookmark';
  const PATH19 = '/clicksCountUp';
  const PATH20 = '/markAsRead';
  const PATH21 = '/pvCountUp';
  const PATH22 = '/relationInfoList';
  const PATH23 = '/api/v1/jobType';
  const PATH24 = '/api/v1/jobs';
  const PATH25 = '/api/v1/jobs/count';
  const PATH26 = '/api/v1/jobs/featured';
  const PATH27 = '/api/v1/jobs/keywords';
  const PATH28 = '/api/v1/jobs/neararea';
  const PATH29 = '/api/v1/jobs/recommendations/interests';
  const PATH30 = '/api/v1/jobs/topics';
  const PATH31 = '/api/v1/jwttest';
  const PATH32 = '/api/v1/prePublishedScout/jobs';
  const PATH33 = '/api/v1/prePublishedScout/jobs/bookmark';
  const PATH34 = '/api/v1/qualifications';
  const PATH35 = '/api/v1/railwayCompany';
  const PATH36 = '/api/v1/savedSearches';
  const PATH37 = '/api/v1/scout/job';
  const PATH38 = '/api/v1/selectJobs';
  const PATH39 = '/reference';
  const PATH40 = '/api/v1/selectJobs/jobs';
  const PATH41 = '/api/v1/selectJobs/jobs/count';
  const PATH42 = '/api/v1/user/service/usageStatus';
  const PATH43 = '/api/v1/users/integration/dodax';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';

  return {
    api: {
      temp: {
        searchcriteria: {
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          post: (option: { body: Methods_qjtpnj['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_qjtpnj['post']['status']>(prefix, PATH0, POST, option).send(),
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          $post: (option: { body: Methods_qjtpnj['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_qjtpnj['post']['status']>(prefix, PATH0, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH0}`,
        },
      },
      v1: {
        abtests: {
          _projectId: (val3: string) => {
            const prefix3 = `${PATH1}/${val3}`;

            return {
              /**
               * 指定した施策ID、割り当てID（指定なければcsp_sessionの候補者ID）を用いてABテスト振分け用のグループNoを取得する
               * @returns 成功
               */
              get: (option?: { query?: Methods_zjar3e['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_zjar3e['get']['resBody'], BasicHeaders, Methods_zjar3e['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した施策ID、割り当てID（指定なければcsp_sessionの候補者ID）を用いてABテスト振分け用のグループNoを取得する
               * @returns 成功
               */
              $get: (option?: { query?: Methods_zjar3e['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_zjar3e['get']['resBody'], BasicHeaders, Methods_zjar3e['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_zjar3e['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            };
          },
          authenticated: {
            _projectId: (val4: string) => {
              const prefix4 = `${PATH2}/${val4}`;

              return {
                /**
                 * 指定した施策ID、割り当てID（認証情報内の候補者ID）を用いてABテスト振分け用のグループNoを取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_4k2f96['get']['resBody'], BasicHeaders, Methods_4k2f96['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定した施策ID、割り当てID（認証情報内の候補者ID）を用いてABテスト振分け用のグループNoを取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_4k2f96['get']['resBody'], BasicHeaders, Methods_4k2f96['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
          },
        },
        area: {
          /**
           * 地域・都道府県・市区町村・政令指定都市マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_zx81u['get']['resBody'], BasicHeaders, Methods_zx81u['get']['status']>(prefix, PATH3, GET, option).json(),
          /**
           * 地域・都道府県・市区町村・政令指定都市マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_zx81u['get']['resBody'], BasicHeaders, Methods_zx81u['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH3}`,
        },
        careerAdvisor: {
          corporateClients: {
            _corporateClientId: (val4: string) => {
              const prefix4 = `${PATH4}/${val4}`;

              return {
                pageLink: {
                  /**
                   * 指定された法人顧客IDに該当する、企業ページへの導線情報を取得する
                   * @returns 成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1bte5gt['get']['resBody'], BasicHeaders, Methods_1bte5gt['get']['status']>(prefix, `${prefix4}${PATH5}`, GET, option).json(),
                  /**
                   * 指定された法人顧客IDに該当する、企業ページへの導線情報を取得する
                   * @returns 成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1bte5gt['get']['resBody'], BasicHeaders, Methods_1bte5gt['get']['status']>(prefix, `${prefix4}${PATH5}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}${PATH5}`,
                },
                /**
                 * 指定された法人顧客IDに該当する、法人顧客情報を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_76f895['get']['resBody'], BasicHeaders, Methods_76f895['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定された法人顧客IDに該当する、法人顧客情報を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_76f895['get']['resBody'], BasicHeaders, Methods_76f895['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
          },
          jobs: {
            _arcsOrderId: (val4: number) => {
              const prefix4 = `${PATH6}/${val4}`;

              return {
                /**
                 * キャリアアドバイザー紹介求人詳細を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_znkox['get']['resBody'], BasicHeaders, Methods_znkox['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * キャリアアドバイザー紹介求人詳細を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_znkox['get']['resBody'], BasicHeaders, Methods_znkox['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
            bookmark: {
              /**
               * キャリアアドバイザー紹介求人の気になる登録を実施する
               * @param option.body - キャリアアドバイザー紹介求人気になる登録リクエストボディ
               */
              post: (option: { body: Methods_1ijkuxd['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ijkuxd['post']['status']>(prefix, PATH7, POST, option).send(),
              /**
               * キャリアアドバイザー紹介求人の気になる登録を実施する
               * @param option.body - キャリアアドバイザー紹介求人気になる登録リクエストボディ
               */
              $post: (option: { body: Methods_1ijkuxd['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ijkuxd['post']['status']>(prefix, PATH7, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH7}`,
            },
            markAsRead: {
              /**
               * キャリアアドバイザー紹介求人詳細を閲覧済み登録を行う
               * @param option.body - キャリアアドバイザー紹介求人閲覧済み登録リクエストボディ
               */
              post: (option: { body: Methods_1ctwem4['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ctwem4['post']['status']>(prefix, PATH8, POST, option).send(),
              /**
               * キャリアアドバイザー紹介求人詳細を閲覧済み登録を行う
               * @param option.body - キャリアアドバイザー紹介求人閲覧済み登録リクエストボディ
               */
              $post: (option: { body: Methods_1ctwem4['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ctwem4['post']['status']>(prefix, PATH8, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH8}`,
            },
            related: {
              otherJobs: {
                /**
                 * 法人顧客IDに関連する求人情報(T原稿のみ)を取得する
                 * @returns 成功
                 */
                get: (option: { query: Methods_ou7n4l['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_ou7n4l['get']['resBody'], BasicHeaders, Methods_ou7n4l['get']['status']>(prefix, PATH9, GET, option).json(),
                /**
                 * 法人顧客IDに関連する求人情報(T原稿のみ)を取得する
                 * @returns 成功
                 */
                $get: (option: { query: Methods_ou7n4l['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_ou7n4l['get']['resBody'], BasicHeaders, Methods_ou7n4l['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_ou7n4l['get']['query'] } | undefined) =>
                  `${prefix}${PATH9}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
            /**
             * キャリアアドバイザー紹介求人一覧を取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_1a4o39s['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1a4o39s['get']['resBody'], BasicHeaders, Methods_1a4o39s['get']['status']>(prefix, PATH6, GET, option).json(),
            /**
             * キャリアアドバイザー紹介求人一覧を取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_1a4o39s['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1a4o39s['get']['resBody'], BasicHeaders, Methods_1a4o39s['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1a4o39s['get']['query'] } | undefined) =>
              `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
        },
        corporateClients: {
          _corporateClientId: (val3: number) => {
            const prefix3 = `${PATH10}/${val3}`;

            return {
              publicationPhotos: {
                /**
                 * 指定した法人顧客IDから企業の承認済み写真一覧を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_v7kqnq['get']['resBody'], BasicHeaders, Methods_v7kqnq['get']['status']>(prefix, `${prefix3}${PATH11}`, GET, option).json(),
                /**
                 * 指定した法人顧客IDから企業の承認済み写真一覧を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_v7kqnq['get']['resBody'], BasicHeaders, Methods_v7kqnq['get']['status']>(prefix, `${prefix3}${PATH11}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH11}`,
              },
            };
          },
        },
        front: {
          job: {
            search: {
              canonical: {
                /**
                 * 求人検索画面のcanonicalURLの置換をする
                 * @returns 成功
                 */
                get: (option: { query: Methods_13lk0ru['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_13lk0ru['get']['resBody'], BasicHeaders, Methods_13lk0ru['get']['status']>(prefix, PATH12, GET, option).json(),
                /**
                 * 求人検索画面のcanonicalURLの置換をする
                 * @returns 成功
                 */
                $get: (option: { query: Methods_13lk0ru['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_13lk0ru['get']['resBody'], BasicHeaders, Methods_13lk0ru['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_13lk0ru['get']['query'] } | undefined) =>
                  `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
              footerLinkPattern: {
                /**
                 * 下部リンクパターンの取得をする
                 * @returns 成功
                 */
                get: (option: { query: Methods_8ls0yn['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_8ls0yn['get']['resBody'], BasicHeaders, Methods_8ls0yn['get']['status']>(prefix, PATH13, GET, option).json(),
                /**
                 * 下部リンクパターンの取得をする
                 * @returns 成功
                 */
                $get: (option: { query: Methods_8ls0yn['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_8ls0yn['get']['resBody'], BasicHeaders, Methods_8ls0yn['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_8ls0yn['get']['query'] } | undefined) =>
                  `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
              titlemeta: {
                /**
                 * SEO関連titleMeta情報の取得をする
                 * @returns 成功
                 */
                get: (option: { query: Methods_1nl9x3n['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1nl9x3n['get']['resBody'], BasicHeaders, Methods_1nl9x3n['get']['status']>(prefix, PATH14, GET, option).json(),
                /**
                 * SEO関連titleMeta情報の取得をする
                 * @returns 成功
                 */
                $get: (option: { query: Methods_1nl9x3n['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1nl9x3n['get']['resBody'], BasicHeaders, Methods_1nl9x3n['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_1nl9x3n['get']['query'] } | undefined) =>
                  `${prefix}${PATH14}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
          },
          jobs: {
            search: {
              label: {
                /**
                 * 指定した検索条件の表示用ラベル文字列を取得する
                 * @returns 成功
                 */
                get: (option?: { query?: Methods_6lsh3h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_6lsh3h['get']['resBody'], BasicHeaders, Methods_6lsh3h['get']['status']>(prefix, PATH15, GET, option).json(),
                /**
                 * 指定した検索条件の表示用ラベル文字列を取得する
                 * @returns 成功
                 */
                $get: (option?: { query?: Methods_6lsh3h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_6lsh3h['get']['resBody'], BasicHeaders, Methods_6lsh3h['get']['status']>(prefix, PATH15, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_6lsh3h['get']['query'] } | undefined) =>
                  `${prefix}${PATH15}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
          },
        },
        industry: {
          /**
           * 業種マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_h9ruy5['get']['resBody'], BasicHeaders, Methods_h9ruy5['get']['status']>(prefix, PATH16, GET, option).json(),
          /**
           * 業種マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_h9ruy5['get']['resBody'], BasicHeaders, Methods_h9ruy5['get']['status']>(prefix, PATH16, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH16}`,
        },
        job: {
          _jid: (val3: string) => {
            const prefix3 = `${PATH17}/${val3}`;

            return {
              bookmark: {
                /**
                 * 気になるを登録する
                 * @param option.body - 気になる登録リクエストボディ
                 */
                post: (option: { body: Methods_1cgl7dt['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1cgl7dt['post']['status']>(prefix, `${prefix3}${PATH18}`, POST, option).send(),
                /**
                 * 気になるを登録する
                 * @param option.body - 気になる登録リクエストボディ
                 */
                $post: (option: { body: Methods_1cgl7dt['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1cgl7dt['post']['status']>(prefix, `${prefix3}${PATH18}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH18}`,
              },
              clicksCountUp: {
                /**
                 * 日別クリック数を更新する
                 * @param option.body - 日別クリック更新リクエストボディ
                 */
                post: (option: { body: Methods_tx49zq['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_tx49zq['post']['status']>(prefix, `${prefix3}${PATH19}`, POST, option).send(),
                /**
                 * 日別クリック数を更新する
                 * @param option.body - 日別クリック更新リクエストボディ
                 */
                $post: (option: { body: Methods_tx49zq['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_tx49zq['post']['status']>(prefix, `${prefix3}${PATH19}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH19}`,
              },
              markAsRead: {
                /**
                 * 指定した求人を閲覧済みとする
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_dxiw0c['post']['status']>(prefix, `${prefix3}${PATH20}`, POST, option).send(),
                /**
                 * 指定した求人を閲覧済みとする
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_dxiw0c['post']['status']>(prefix, `${prefix3}${PATH20}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH20}`,
              },
              pvCountUp: {
                /**
                 * 日別PV数を更新する
                 * @param option.body - 日別PV数更新リクエストボディ
                 */
                post: (option: { body: Methods_1ggb4dr['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1ggb4dr['post']['status']>(prefix, `${prefix3}${PATH21}`, POST, option).send(),
                /**
                 * 日別PV数を更新する
                 * @param option.body - 日別PV数更新リクエストボディ
                 */
                $post: (option: { body: Methods_1ggb4dr['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1ggb4dr['post']['status']>(prefix, `${prefix3}${PATH21}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH21}`,
              },
              relationInfoList: {
                _corporateClientId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH22}/${val5}`;

                  return {
                    /**
                     * 指定した求人IDと法人顧客IDを基に、企業が募集中のその他求人情報を取得する
                     * @returns 成功
                     */
                    get: (option: { query: Methods_1njf7b1['get']['query'], config?: T | undefined }) =>
                      fetch<Methods_1njf7b1['get']['resBody'], BasicHeaders, Methods_1njf7b1['get']['status']>(prefix, prefix5, GET, option).json(),
                    /**
                     * 指定した求人IDと法人顧客IDを基に、企業が募集中のその他求人情報を取得する
                     * @returns 成功
                     */
                    $get: (option: { query: Methods_1njf7b1['get']['query'], config?: T | undefined }) =>
                      fetch<Methods_1njf7b1['get']['resBody'], BasicHeaders, Methods_1njf7b1['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get' | undefined; query: Methods_1njf7b1['get']['query'] } | undefined) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                  };
                },
              },
              /**
               * 指定した求人IDから求人の詳細情報を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1if69eo['get']['resBody'], BasicHeaders, Methods_1if69eo['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した求人IDから求人の詳細情報を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1if69eo['get']['resBody'], BasicHeaders, Methods_1if69eo['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
        jobType: {
          /**
           * 職種マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1kgnldy['get']['resBody'], BasicHeaders, Methods_1kgnldy['get']['status']>(prefix, PATH23, GET, option).json(),
          /**
           * 職種マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1kgnldy['get']['resBody'], BasicHeaders, Methods_1kgnldy['get']['status']>(prefix, PATH23, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH23}`,
        },
        jobs: {
          count: {
            /**
             * 求人件数を取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_3quz6n['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_3quz6n['get']['resBody'], BasicHeaders, Methods_3quz6n['get']['status']>(prefix, PATH25, GET, option).json(),
            /**
             * 求人件数を取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_3quz6n['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_3quz6n['get']['resBody'], BasicHeaders, Methods_3quz6n['get']['status']>(prefix, PATH25, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_3quz6n['get']['query'] } | undefined) =>
              `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          featured: {
            /**
             * 今週の注目求人一覧を取得する
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1hk2ini['get']['resBody'], BasicHeaders, Methods_1hk2ini['get']['status']>(prefix, PATH26, GET, option).json(),
            /**
             * 今週の注目求人一覧を取得する
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1hk2ini['get']['resBody'], BasicHeaders, Methods_1hk2ini['get']['status']>(prefix, PATH26, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH26}`,
          },
          keywords: {
            /**
             * フリーワード検索時に類似キーワードを取得する
             * @returns 成功
             */
            get: (option: { query: Methods_ipxlww['get']['query'], config?: T | undefined }) =>
              fetch<Methods_ipxlww['get']['resBody'], BasicHeaders, Methods_ipxlww['get']['status']>(prefix, PATH27, GET, option).json(),
            /**
             * フリーワード検索時に類似キーワードを取得する
             * @returns 成功
             */
            $get: (option: { query: Methods_ipxlww['get']['query'], config?: T | undefined }) =>
              fetch<Methods_ipxlww['get']['resBody'], BasicHeaders, Methods_ipxlww['get']['status']>(prefix, PATH27, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_ipxlww['get']['query'] } | undefined) =>
              `${prefix}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          neararea: {
            /**
             * 指定した検索条件から近隣エリアを取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_1nt8tj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1nt8tj['get']['resBody'], BasicHeaders, Methods_1nt8tj['get']['status']>(prefix, PATH28, GET, option).json(),
            /**
             * 指定した検索条件から近隣エリアを取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_1nt8tj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1nt8tj['get']['resBody'], BasicHeaders, Methods_1nt8tj['get']['status']>(prefix, PATH28, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1nt8tj['get']['query'] } | undefined) =>
              `${prefix}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          recommendations: {
            interests: {
              /**
               * ユーザー情報からユーザーの興味に近い求人一覧を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_o81vz4['get']['resBody'], BasicHeaders, Methods_o81vz4['get']['status']>(prefix, PATH29, GET, option).json(),
              /**
               * ユーザー情報からユーザーの興味に近い求人一覧を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_o81vz4['get']['resBody'], BasicHeaders, Methods_o81vz4['get']['status']>(prefix, PATH29, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH29}`,
            },
          },
          topics: {
            _id: (val4: string) => {
              const prefix4 = `${PATH30}/${val4}`;

              return {
                /**
                 * 指定した特集枠IDを基に特集枠情報および求人検索条件を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_16zdarc['get']['resBody'], BasicHeaders, Methods_16zdarc['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定した特集枠IDを基に特集枠情報および求人検索条件を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_16zdarc['get']['resBody'], BasicHeaders, Methods_16zdarc['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
          },
          /**
           * 指定した検索条件から求人一覧を取得する
           * @returns 成功
           */
          get: (option?: { query?: Methods_108luin['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_108luin['get']['resBody'], BasicHeaders, Methods_108luin['get']['status']>(prefix, PATH24, GET, option).json(),
          /**
           * 指定した検索条件から求人一覧を取得する
           * @returns 成功
           */
          $get: (option?: { query?: Methods_108luin['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_108luin['get']['resBody'], BasicHeaders, Methods_108luin['get']['status']>(prefix, PATH24, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_108luin['get']['query'] } | undefined) =>
            `${prefix}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        jwttest: {
          /**
           * テスト
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_13ju89s['get']['resBody'], BasicHeaders, Methods_13ju89s['get']['status']>(prefix, PATH31, GET, option).text(),
          /**
           * テスト
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_13ju89s['get']['resBody'], BasicHeaders, Methods_13ju89s['get']['status']>(prefix, PATH31, GET, option).text().then(r => r.body),
          $path: () => `${prefix}${PATH31}`,
        },
        prePublishedScout: {
          jobs: {
            _prePublishedScoutId: (val4: number) => {
              const prefix4 = `${PATH32}/${val4}`;

              return {
                /**
                 * 掲載前スカウトに紐づく求人詳細を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1aqc38a['get']['resBody'], BasicHeaders, Methods_1aqc38a['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 掲載前スカウトに紐づく求人詳細を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1aqc38a['get']['resBody'], BasicHeaders, Methods_1aqc38a['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
            bookmark: {
              /**
               * 掲載前スカウト求人を気になる登録する
               * @param option.body - 掲載前スカウト求人気になる登録リクエストボディ
               */
              post: (option: { body: Methods_9h9im2['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_9h9im2['post']['status']>(prefix, PATH33, POST, option).send(),
              /**
               * 掲載前スカウト求人を気になる登録する
               * @param option.body - 掲載前スカウト求人気になる登録リクエストボディ
               */
              $post: (option: { body: Methods_9h9im2['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_9h9im2['post']['status']>(prefix, PATH33, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH33}`,
            },
          },
        },
        qualifications: {
          /**
           * 資格一覧情報を取得する
           * @returns 成功
           */
          get: (option?: { query?: Methods_c74r9t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_c74r9t['get']['resBody'], BasicHeaders, Methods_c74r9t['get']['status']>(prefix, PATH34, GET, option).json(),
          /**
           * 資格一覧情報を取得する
           * @returns 成功
           */
          $get: (option?: { query?: Methods_c74r9t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_c74r9t['get']['resBody'], BasicHeaders, Methods_c74r9t['get']['status']>(prefix, PATH34, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_c74r9t['get']['query'] } | undefined) =>
            `${prefix}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        railwayCompany: {
          /**
           * 鉄道マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { query?: Methods_zee37p['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_zee37p['get']['resBody'], BasicHeaders, Methods_zee37p['get']['status']>(prefix, PATH35, GET, option).json(),
          /**
           * 鉄道マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { query?: Methods_zee37p['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_zee37p['get']['resBody'], BasicHeaders, Methods_zee37p['get']['status']>(prefix, PATH35, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_zee37p['get']['query'] } | undefined) =>
            `${prefix}${PATH35}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        savedSearches: {
          _no: (val3: string) => {
            const prefix3 = `${PATH36}/${val3}`;

            return {
              /**
               * 指定した検索条件を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_itimew['get']['resBody'], BasicHeaders, Methods_itimew['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した検索条件を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_itimew['get']['resBody'], BasicHeaders, Methods_itimew['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              /**
               * 指定した検索条件を更新する
               * @param option.body - 検索条件保存リクエストボディ
               */
              put: (option: { body: Methods_itimew['put']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_itimew['put']['status']>(prefix, prefix3, PUT, option).send(),
              /**
               * 指定した検索条件を更新する
               * @param option.body - 検索条件保存リクエストボディ
               */
              $put: (option: { body: Methods_itimew['put']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_itimew['put']['status']>(prefix, prefix3, PUT, option).send().then(r => r.body),
              /**
               * 指定した検索条件を削除する
               */
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_itimew['delete']['status']>(prefix, prefix3, DELETE, option).send(),
              /**
               * 指定した検索条件を削除する
               */
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_itimew['delete']['status']>(prefix, prefix3, DELETE, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          /**
           * ユーザーに紐づく検索条件を全て取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1arbdhu['get']['resBody'], BasicHeaders, Methods_1arbdhu['get']['status']>(prefix, PATH36, GET, option).json(),
          /**
           * ユーザーに紐づく検索条件を全て取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1arbdhu['get']['resBody'], BasicHeaders, Methods_1arbdhu['get']['status']>(prefix, PATH36, GET, option).json().then(r => r.body),
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          post: (option: { body: Methods_1arbdhu['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_1arbdhu['post']['status']>(prefix, PATH36, POST, option).send(),
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          $post: (option: { body: Methods_1arbdhu['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_1arbdhu['post']['status']>(prefix, PATH36, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH36}`,
        },
        scout: {
          job: {
            _jid: (val4: string) => {
              const prefix4 = `${PATH37}/${val4}`;

              return {
                markAsRead: {
                  /**
                   * 指定した求人を閲覧済みとする。
                   * <br/>閲覧履歴の保存処理と閲覧履歴クッキーの付与を行う。
                   * <br/>リクエストパラメータにスカウトIDが存在し尚且つDR求人の場合は、専用の閲覧履歴の保存処理(スカウトメールの開封)を追加で行う。)
                   * @param option.body - スカウト求人リクエストボディ
                   */
                  post: (option: { body: Methods_1rp3slj['post']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1rp3slj['post']['status']>(prefix, `${prefix4}${PATH20}`, POST, option).send(),
                  /**
                   * 指定した求人を閲覧済みとする。
                   * <br/>閲覧履歴の保存処理と閲覧履歴クッキーの付与を行う。
                   * <br/>リクエストパラメータにスカウトIDが存在し尚且つDR求人の場合は、専用の閲覧履歴の保存処理(スカウトメールの開封)を追加で行う。)
                   * @param option.body - スカウト求人リクエストボディ
                   */
                  $post: (option: { body: Methods_1rp3slj['post']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1rp3slj['post']['status']>(prefix, `${prefix4}${PATH20}`, POST, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}${PATH20}`,
                },
                /**
                 * 指定した求人ID/スカウトIDから求人の詳細情報を取得する
                 * @returns 成功
                 */
                get: (option?: { query?: Methods_jr3h7h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_jr3h7h['get']['resBody'], BasicHeaders, Methods_jr3h7h['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定した求人ID/スカウトIDから求人の詳細情報を取得する
                 * @returns 成功
                 */
                $get: (option?: { query?: Methods_jr3h7h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_jr3h7h['get']['resBody'], BasicHeaders, Methods_jr3h7h['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_jr3h7h['get']['query'] } | undefined) =>
                  `${prefix}${prefix4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              };
            },
          },
        },
        selectJobs: {
          _selectJobCode: (val3: string) => {
            const prefix3 = `${PATH38}/${val3}`;

            return {
              reference: {
                /**
                 * 指定した特集コードに紐づくテーマが似ている求人特集一覧を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1en3614['get']['resBody'], BasicHeaders, Methods_1en3614['get']['status']>(prefix, `${prefix3}${PATH39}`, GET, option).json(),
                /**
                 * 指定した特集コードに紐づくテーマが似ている求人特集一覧を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1en3614['get']['resBody'], BasicHeaders, Methods_1en3614['get']['status']>(prefix, `${prefix3}${PATH39}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH39}`,
              },
              /**
               * 指定した特集コードから特集の詳細情報を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1y73qny['get']['resBody'], BasicHeaders, Methods_1y73qny['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した特集コードから特集の詳細情報を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1y73qny['get']['resBody'], BasicHeaders, Methods_1y73qny['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          jobs: {
            count: {
              /**
               * 指定した検索条件で特集求人一覧件数を取得する
               * @param option.body - 特集求人一覧検索条件リクエストボディ
               * @returns 成功
               */
              post: (option: { body: Methods_xnzz7g['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_xnzz7g['post']['resBody'], BasicHeaders, Methods_xnzz7g['post']['status']>(prefix, PATH41, POST, option).json(),
              /**
               * 指定した検索条件で特集求人一覧件数を取得する
               * @param option.body - 特集求人一覧検索条件リクエストボディ
               * @returns 成功
               */
              $post: (option: { body: Methods_xnzz7g['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_xnzz7g['post']['resBody'], BasicHeaders, Methods_xnzz7g['post']['status']>(prefix, PATH41, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH41}`,
            },
            /**
             * 指定した検索条件で特集求人一覧を取得する
             * @param option.body - 特集求人一覧検索条件リクエストボディ
             * @returns 成功
             */
            post: (option: { body: Methods_1lob0jk['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1lob0jk['post']['resBody'], BasicHeaders, Methods_1lob0jk['post']['status']>(prefix, PATH40, POST, option).json(),
            /**
             * 指定した検索条件で特集求人一覧を取得する
             * @param option.body - 特集求人一覧検索条件リクエストボディ
             * @returns 成功
             */
            $post: (option: { body: Methods_1lob0jk['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1lob0jk['post']['resBody'], BasicHeaders, Methods_1lob0jk['post']['status']>(prefix, PATH40, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH40}`,
          },
        },
        user: {
          service: {
            usageStatus: {
              /**
               * ログインユーザーのサービス利用状況を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_kzqanu['get']['resBody'], BasicHeaders, Methods_kzqanu['get']['status']>(prefix, PATH42, GET, option).json(),
              /**
               * ログインユーザーのサービス利用状況を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_kzqanu['get']['resBody'], BasicHeaders, Methods_kzqanu['get']['status']>(prefix, PATH42, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH42}`,
            },
          },
        },
        users: {
          integration: {
            dodax: {
              /**
               * dodaXから連携された情報でdodaに会員登録する
               * @param option.body - dodaX連携会員登録リクエストボディ
               */
              post: (option: { body: Methods_1w5ssoh['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1w5ssoh['post']['status']>(prefix, PATH43, POST, option).send(),
              /**
               * dodaXから連携された情報でdodaに会員登録する
               * @param option.body - dodaX連携会員登録リクエストボディ
               */
              $post: (option: { body: Methods_1w5ssoh['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1w5ssoh['post']['status']>(prefix, PATH43, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH43}`,
            },
          },
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
